<template>
  <a-form class="iotplt-nested-search-form" :form="form" @submit="handleSearch">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-form-item label="状态">
          <a-select
            v-decorator="['status']"
            allow-clear
          >
            <a-select-option key="success" value="success">
              成功
            </a-select-option>
            <a-select-option key="fail" value="fail">
              失败
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <a-space>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
            <a-button @click="handleReset">
              刷新
            </a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>
<script>

export default {
  name: 'BatchOperationRecordCommonDetailsSearch',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'batch_operation_details_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
