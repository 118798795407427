import request from '@/utils/request'

// 查询批量操作详情
export function findBatchOperationDetails(params) {
  return request({
    url: `/batch_operation_details`,
    method: 'get',
    params: params
  })
}

// 导出批量操作详情
export function exportBatchOperationDetails(data) {
  return request({
    url: `/batch_operation_details/export`,
    method: 'post',
    data
  })
}

