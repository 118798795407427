<template>
  <a-row>
    <a-col :span="12">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-6"
      >
        <a-descriptions-item label="入库批次">
          {{ batchOperationRecordInfo.operation_no }}
        </a-descriptions-item>
        <a-descriptions-item label="入库时间">
          {{ batchOperationRecordInfo.created_at }}
        </a-descriptions-item>
        <a-descriptions-item label="入库人">
          {{ batchOperationRecordInfo.operator_name }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          <span class="iotplt-word-break-all">
            {{ batchOperationRecordInfo.remark }}
          </span>
        </a-descriptions-item>
      </a-descriptions>
    </a-col>

    <a-col :span="12">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-6"
      >
        <a-descriptions-item label="入库总数">
          {{ batchOperationRecordInfo.operation_count }}
        </a-descriptions-item>
        <a-descriptions-item label="入库成功数">
          {{ batchOperationRecordInfo.success_count }}
        </a-descriptions-item>
        <a-descriptions-item label="入库失败数">
          {{ batchOperationRecordInfo.failure_count }}
        </a-descriptions-item>
        <a-descriptions-item label="重跑次数">
          {{ batchOperationRecordInfo.run_times }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'BatchOperationRecordAllocateCardsToAgencyAgencyViewInfo',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>
